import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Moment from 'react-moment'

import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'
import Pagination from '../components/Pagination'
import { Wrapper } from '../components/styles/grid'
import { PageHeading, FooterDark } from '../components/styles/elements'
import { Colors, Fonts, TypeScale, media } from '../components/styles/variables'

const Article = styled.article`
	grid-column: 2 / span 13;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid ${Colors.light};

	${media.greaterThan('small')`
		grid-column: 2 / span 10;
	`}
`

const ArticleTitle = styled.h2`
	font-family: ${Fonts.serif};
	font-size: ${TypeScale.three};
	line-height: 1.5;
	margin-bottom: .5em;

	> a {
		text-decoration: none;
	}
`

const Divider = styled.span`
	display: inline-block;
	position: relative;
	padding: 0 10px;
`

const ArticlesPage = (props) => {
	const { currentPage, numPages } = props.pageContext
	const posts = props.data.allMarkdownRemark.edges

	return (
		<LayoutDark>
			<Seo title='Articles' />
			<Wrapper as='main'>
				<PageHeading>Articles</PageHeading>

				{posts.map(({ node }) => (
					<Article key={node.id}>
						<ArticleTitle>
							<Link to={`/${node.fields.slug}`}>
								{node.frontmatter.title}
							</Link>
						</ArticleTitle>
						<p>
							<Moment format="Do MMMM YYYY">
								{node.frontmatter.date}
							</Moment>
							<Divider>|</Divider>
							{node.timeToRead} minute read
						</p>
						<p>{node.excerpt}</p>
					</Article>
				))}

				<Pagination currentPage={currentPage} numPages={numPages} />
			</Wrapper>
			<FooterDark />
		</LayoutDark>
	)
}

export default ArticlesPage

export const query = graphql`
query ArticlesPageQuery($skip: Int!, $limit: Int!) {
	allMarkdownRemark(
			sort: {fields: [frontmatter___date], order: DESC}
			limit: $limit
			skip: $skip
		) {
			totalCount
			edges {
				node {
					frontmatter {
						title
						date
						author
					}
					fields {
						slug
					}
					id
					excerpt
					timeToRead
				}
			}
	}
}
`
