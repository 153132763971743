import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Colors, media } from './styles/variables'

const PaginationNav = styled.nav`
    grid-column: 2 / span 13;
    margin-bottom: 20px;
    padding-top: 20px;

    ${media.greaterThan('small')`
		grid-column: 2 / span 10;
	`}
`

const PaginationLink = styled(Link)`
    padding: .2em .5em;
    margin: 0 5px 0 0;
    text-decoration: none;

    &:hover {
        box-shadow: none;
    }

    &.active {
        background-color: ${Colors.orangeDark};
        color: ${Colors.dark};
    }

    ${media.lessThan('small')`
		display: ${props => props.hidesmall ? 'none' : 'inline-block' };
	`}
`

const Pagination = (props) => {
    const currentPage = props.currentPage
    const numPages = props.numPages

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = `/articles/${currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()}`
    const nextPage = `/articles/${(currentPage + 1).toString()}`

    return (
        <PaginationNav>
            {!isFirst && (
                <PaginationLink to={prevPage} rel="prev" hidesmall='true'>
                    ← Previous Page
                </PaginationLink>
            )}
            
            {Array.from({ length: numPages }, (_, i) => (
                <PaginationLink key={`pagination-number${i + 1}`} activeClassName='active' to={`/articles/${i === 0 ? "" : i + 1}`}>
                    {i + 1}
                </PaginationLink>
            ))}
            {!isLast && (
                <PaginationLink to={nextPage} rel="next" hidesmall='true'>
                    Next Page →
                </PaginationLink>
            )}
        </PaginationNav>
    )
}

export default Pagination